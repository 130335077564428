<template>
  <div class="app-container">
    <div class="filter-container">
      年级:{{ data.class_course.classes.grade.name }}&nbsp;&nbsp; 学期:{{
        data.class_course.semester == 1 ? "第一学期" : "第二学期"
      }}&nbsp;&nbsp; 院系:{{
        data.class_course.classes.college.name
      }}&nbsp;&nbsp; 专业:{{ data.class_course.classes.major.name }}&nbsp;&nbsp;
      班级:{{ data.class_course.classes.name }}
    </div>
    <div class="filter-container">
      <!-- <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button> -->
      <el-input
        v-model="listQuery.student_name"
        placeholder="请输入学生姓名"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.student_no"
        placeholder="请输入学号"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="未完成" :value="1" />
        <el-option label="已完成" :value="2" />
        <el-option label="已逾期" :value="3" />
      </el-select>
      <el-select
        v-model="listQuery.is_score"
        placeholder="评分"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="已评分" :value="1" />
        <el-option label="未评分" :value="0" />
      </el-select>
      <download-excel
        :fields="json_fields"
        :fetch="handleExport"
        type="xls"
        name="班级作业.xls"
        worksheet="班级作业"
        :header="data.class_course.classes.name + '作业'"
        class="filter-item"
      >
        <el-button style="margin-left: 10px" type="warning">
          一键导出
        </el-button>
      </download-excel>
      <el-button class="filter-item" type="primary" style="margin-left: 10px" @click="onekeyDowload">
        一键下载
      </el-button>
      <el-button class="filter-item" type="primary" @click="handleFilter">
        搜索
      </el-button>
      <el-button class="filter-item" type="primary" @click="handlerSetting">
        设置作业
      </el-button>
      <el-button class="filter-item" type="warning" @click="sendMessage">
        消息提醒
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="学生姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_student.student.name }}
        </template>
      </el-table-column>
      <el-table-column label="学号" min-width="110" align="center">
        <template slot-scope="scope"
          >{{ scope.row.class_student.student.account }}
        </template>
      </el-table-column>
      <el-table-column label="标题" min-width="110" align="center">
        <template slot-scope="scope"
          >{{ scope.row.schedule.schedule_homework.title }}
        </template>
      </el-table-column>
      <el-table-column label="完成状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="warning" v-if="scope.row.status == 1">未完成</el-tag>
          <el-tag type="success" v-else-if="scope.row.status == 2"
            >已完成</el-tag
          >
          <el-tag type="danger" v-else-if="scope.row.status == 3"
            >已逾期</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="附件" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.file ? scope.row.file.name : "" }}
          <el-button
            v-if="scope.row.file"
            type="primary"
            size="mini"
            @click="download(scope.row.file.url, scope.row.file.name)"
          >
            下载
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="完成时间" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.finish_at }} </template>
      </el-table-column>
      <el-table-column label="截止时间" min-width="110" align="center">
        <template slot-scope="scope"
          >{{ scope.row.schedule.schedule_homework.deadline }}
        </template>
      </el-table-column>
      <el-table-column label="作业评分" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.score }} </template>
      </el-table-column>
      <el-table-column label="作业批注" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.annotation }} </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            v-if="scope.row.status == 2"
            @click="handlerDetile(scope.row, 1)"
          >
            批改作业
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="handlerDetile(scope.row, 2)"
          >
            查看作业详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      title="设置作业"
      :visible.sync="dialogSetting"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="作业标题" prop="title">
          <el-input type="text" v-model="form.title" clearable />
        </el-form-item>

        <el-form-item label="作业截至时间" prop="deadline">
          <el-date-picker
            v-model="form.deadline"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="附件">
          <div v-for="(item, index) in form.files" :key="index">
            <span>文件名: {{ item.name }}</span>
            <el-button
              s
              size="mini"
              type="danger"
              @click="delFiles(index)"
              style="margin-left: 20px"
            >
              删除
            </el-button>
          </div>
          <!-- files -->
          <el-upload
            ref="upload"
            class="upload-demo"
            style="width: 100px"
            :action="qiniu.actionPath"
            :data="qiniu.postData"
            multiple
            accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.mp3,.pdf,.zip,.rar"
            :on-success="uploadSuccess"
            :on-error="uploadError"
            :before-upload="beforeUpload"
            :show-file-list="false"
          >
            <el-button size="mini" style="margin-top: 10px"> 添加 </el-button>
          </el-upload>
        </el-form-item>

        <el-form-item label="作业内容" prop="content">
          <tinymce :height="500" v-model="form.content" :value="form.content" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSetting = false">取消</el-button>
        <el-button
          type="primary"
          @click="settingHomework"
          :loading="btnLoading"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import request from "@/utils/request";
import JSZip from "jszip";
import fileSaver from "file-saver";
import axios from "axios";
import { getQiniuToken } from "@/api/common";
import { guid } from "@/utils/index";
import downloadExcel from "vue-json-excel";
const getFile = (url) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "arraybuffer",
    })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error.toString());
      });
  });
};

export default {
  components: {
    Tinymce,
    downloadExcel,
  },
  data() {
    return {
      list: [],
      total: null,
      listLoading: false,
      school_year: [],
      listQuery: {
        page: 1,
        limit: 10,
        schedule_id: null,
        student_name: "",
        student_no: "",
        status: "",
        is_score: "",
      },
      dialogSetting: false,
      dialogCorrect: false,
      dialogDetile: false,
      form: {
        id: "",
        schedule_id: "",
        title: "",
        deadline: "",
        content: "",
        files: [],
      },
      correctForm: {
        id: "",
        score: "",
        annotation: "",
        file: "",
      },
      correctDetile: {
        file: "",
        content: "",
        images: [],
      },
      qiniu: {
        actionPath: "",
        baseUrl: "",
        postData: {},
      },
      rules: {
        title: [{ required: true, message: "标题不能为空", trigger: "change" }],
        deadline: [
          { required: true, message: "时间不能为空", trigger: "change" },
        ],
        content: [
          { required: true, message: "内容不能为空", trigger: "change" },
        ],
      },
      correctRules: {
        score: [{ required: true, message: "评分不能为空", trigger: "change" }],
        annotation: [
          { required: true, message: "批注不能为空", trigger: "change" },
        ],
      },
      btnLoading: false,
      data: {},
      //导出
      json_fields: {
        学生姓名: "class_student.student.name",
        学号: "class_student.student.account",
        作业标题: "schedule.schedule_homework.title",
        作业评分: "score",
        老师批注: "annotation",
      },
    };
  },
  watch: {
    "form.deadline"(newValue, old) {
      this.form.deadline = newValue.replace("00:00:00", "23:59:59");
    },
  },

  created() {
    getQiniuToken().then((response) => {
      console.log(response);
      // token可多次使用
      this.qiniu.actionPath = response.data.uploadUrl;
      this.qiniu.baseUrl = response.data.baseUrl;
      // console.log(response)
      if (!this.qiniu.postData.token) {
        this.qiniu.postData.token = response.data.token;
      }
    });
    this.listQuery.schedule_id = this.$route.query.schedule_id;
    this.data = JSON.parse(this.$route.query.data); //字符串转对象
    console.log(this.data);
    this.getList();
  },
  methods: {
    handleExport() {
      this.listQuery.limit = 99999999999999;
      return request({
        url: "/api/teacherend/schedule/student",
        method: "get",
        params: this.listQuery,
      })
        .then((result) => {
          console.log(result.data.data);
          var json_data = result.data.data;
          // this.header = this.data.class_course.classes.name;

          return json_data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    delFiles(index) {
      this.form.files.splice(index, 1);
    },
    beforeUpload(file) {
      this.dialogVisible = true;
      this.qiniu.postData.key =
        guid() + "." + file.name.split(".")[file.name.split(".").length - 1];
    },
    uploadSuccess(response, file, fileList) {
      let url = {
        url: "",
        name: "",
      };
      url.url = this.qiniu.baseUrl + "/" + response.key;
      url.name = file.name;
      // if (this.list.length == 0) {
      //   this.list = this.dataList.data;
      // }
      this.form.files.push(url);
    },
    uploadError() {},
    // schedule/sendHomeworkMessage
    sendMessage() {
      request({
        url: "/api/teacherend/schedule/sendHomeworkMessage",
        method: "post",
        data: {
          class_id: this.data.class_course.class_id,
        },
      }).then((res) => {
        this.$message({
          message: "发送成功!",
          type: "success",
        });
      });
    },
    judgeType(name) {
      let result = "";
      let suffix = "";
      try {
        var flieArr = name.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      let imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      result = imglist.some(function (item) {
        return item == suffix;
      });
      return result;
    },
    getUrlBase64(url) {
      return new Promise((resolve) => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let img = new Image();
        img.crossOrigin = "Anonymous"; //允许跨域
        img.src = url;
        img.onload = function () {
          canvas.height = 300;
          canvas.width = 300;
          ctx.drawImage(img, 0, 0, 300, 300);
          let dataURL = canvas.toDataURL("image/png");
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    onekeyDowload() {
      const data = [];
      this.list.forEach((item) => {
        console.log(item, "==============");
        if (item.finish_at) {
          let routeUrl = item.file.url.replace(/http:/, "https:");
          data.push(routeUrl);
        }
      });
      console.log(data, "=============");
      // return
      // 需要下载打包的路径, 可以是本地相对路径, 也可以是跨域的全路径
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      data.forEach((item) => {
        const promise = getFile(item).then((data) => {
          // 下载文件, 并存成ArrayBuffer对象
          const arr_name = item.split("/");
          const file_name = arr_name[arr_name.length - 1]; // 获取文件名
          zip.file(file_name, data, { binary: true }); // 逐个添加文件
          cache[file_name] = data;
        });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          // 生成二进制流
          fileSaver.saveAs(
            content,
            `${this.data.class_course.classes.name}作业.zip`
          ); // 利用file-saver保存文件
        });
      });
    },
    download(item, name) {
      let res = this.judgeType(item);
      console.log(item, "==========");
      if (res) {
        this.getUrlBase64(item).then((base64) => {
          let link = document.createElement("a");
          link.href = base64;
          link.download = item.name || name;
          link.click();
        });
      } else {
        let routeUrl = item.replace(/http:/, "https:");
        window.open(routeUrl, "_parent");
      }
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/schedule/student",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.list.forEach((item) => {
          if (item.file) {
            item.file = JSON.parse(item.file);
          }
        });
        console.log(this.list);
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getHomeworkDetail() {
      request({
        url: "/api/teacherend/schedule/homeworkDetail",
        method: "get",
        params: {
          schedule_id: this.listQuery.schedule_id,
        },
      }).then((response) => {
        if (response.data) {
          this.form = response.data;
          if (this.form.files == null) {
            this.form.files = [];
          }
        }
      });
    },
    homeworkCorrecting() {
      request({
        url: "/api/teacherend/schedule/homeworkCorrecting",
        method: "post",
        data: this.correctForm,
      }).then((response) => {});
    },
    settingHomework() {
      if (+new Date(this.form.deadline) <= +new Date()) {
        this.$message({
          message: "设置作业不能小于当前时间!",
          type: "error",
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/teacherend/schedule/settingHomework",
        method: "post",
        data: this.form,
      })
        .then((response) => {
          this.form = {
            id: "",
            schedule_id: "",
            title: "",
            deadline: "",
            content: "",
          };
          // this.form.content = '1111';
          this.$forceUpdate(); //强制更新
          console.log(this.form, "===================");
          this.$message({
            message: "设置成功!",
            type: "success",
          });
          this.btnLoading = false;
          this.dialogSetting = false;
          this.getList();
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handlerSetting(row) {
      this.dialogSetting = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.form.schedule_id = this.listQuery.schedule_id;
      this.getHomeworkDetail();
    },
    handlerCorrect(row) {
      this.dialogCorrect = true;
      this.correctForm.id = row.id;
      this.correctForm.score = row.score;
      this.correctForm.annotation = row.annotation;
      this.correctForm.file = row.file;
      this.$nextTick(() => {
        this.$refs["correctForm"].clearValidate();
      });
    },
    handlerDetile(row, type) {
      let data = {
        id: row.id,
        score: row.score,
        annotation: row.annotation,
        file: row.file,
        content: row.content,
        images: row.images,
      };
      this.$router.push(
        `/schedule/operation_details?data=${JSON.stringify(data)}&type=${type}`
      );
      // this.dialogDetile = true;
      // this.correctDetile.file = row.file;
      // this.correctDetile.content = row.content;
      // this.correctDetile.images = row.images;
    },
    saveData() {
      this.$refs["correctForm"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/teacherend/schedule/homeworkCorrecting",
            method: "post",
            data: this.correctForm,
          })
            .then((response) => {
              this.btnLoading = false;
              // this.dialogSetting = false;
              this.dialogCorrect = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该院系, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/course/categoryUpDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    // 获取省
    getProvince() {
      return request({
        url: "/api/common/area/index",
        method: "get",
      }).then((res) => {
        this.provinces = res.data;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dialogDetile_box {
  font-size: 17px;
  span {
    font-weight: 600;
  }
  .dialogDetile_text {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  img {
    width: 240px;
    // height: ;
    margin-right: 5px;
  }
}
</style>
